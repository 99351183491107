<style scoped>
  /*/deep/ .menu-list a {
    display: contents;
  }*/
</style>

<template>
  <div class="menu">
    <p
      v-if="options.label"
      class="menu-label"
    >
      {{ options.label }}<br>
    </p>
    <p
      v-if="!isUndefined(options.hideParts) || !isUndefined(options.hideHeaders)"
    >
      <b-field
        grouped
        class="is-pulled-right"
      >
        <b-switch
          v-if="!isUndefined(options.hideParts)"
          :value="options.hideParts"
          @input="hideParts"
        >
          Hide parts
        </b-switch>
        <b-switch
          v-if="!isUndefined(options.hideHeaders)"
          :value="options.hideHeaders"
          @input="hideHeaders"
        >
          Hide headers
        </b-switch>
      </b-field>
    </p>
    <div class="is-clearfix" />
    <ul class="menu-list">
      <locales-node
        :node="rootNode"
        :nodes="newNodes"
        :parts="newParts"
        :options="newOptions"
        @select="select"
        @remove="remove"
        @duplicate="duplicate"
      />
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isUndefined } from 'lodash'
import Vue from 'vue'
import LocalesNode from './LocalesNode'

export default {
  name: 'LocalesTree',
  components: {
    LocalesNode
  },
  props: {
    value: {
      type: Object|Boolean,
      required: false
    },
    nodes: {
      type: Array,
      default: []
    },
    parts: {
      type: Array,
      default: []
    },
    options: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      rootNode: {
        id: null,
        part:
          { name: 'Root node' },
        active: false
      }
    }
  },
  computed: {
    newNodes () {
      return _.map(this.nodes, node => {
        Vue.set(node, 'active', false)
        if (this.value && node.id === this.value.id) {
          Vue.set(node, 'active', true)
        }
        return node
      })
    },
    newParts () {
      return _.map(this.parts, node => {
        Vue.set(node, 'active', false)
        return node
      })
    },
    newOptions () {
      return _.assign({
        canUpdate: false,
        selectOnly: true
      }, this.options)
    }
  },
  created () {
    console.log('nodeTree value', this.value)
    this.resetNodes()
    // if(this.value)
    this.select(this.value, false)
  },
  methods: {
    isUndefined,
    resetNodes () {
      this.rootNode.active = false
      Vue.set(this.rootNode, 'active', false)
      _.forEach(this.newNodes, node => {
        Vue.set(node, 'active', false)
      })
      _.forEach(this.newParts, node => {
        Vue.set(node, 'active', false)
      })
    },
    select (value, emit = true) {
      this.resetNodes()
      if (value === null || value.id === null) {
        this.rootNode.active = true
        console.log('selected', 'root')
      } else {
        let selected = _.find(this.newNodes, { id: value.id })
        if (selected) {
          selected.active = true
          console.log('selected', value.id)
        }
        selected = _.find(this.newParts, { id: value.id })
        if (selected) {
          selected.active = true
        }
      }
      if(emit) {
        this.$emit('input', value && value.id ? _.pick(value, ['id', 'number', 'part', 'parent']) : null)
      }
    },
    remove (value) {
      this.$emit('remove', value)
    },
    duplicate (value) {
      this.$emit('duplicate', value)
    },
    hideParts (value) {
      this.$emit('hideParts', value)
    },
    hideHeaders (value) {
      this.$emit('hideHeaders', value)
    }
  }
}
</script>
